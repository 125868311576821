import React from 'react';
import { Helmet } from 'react-helmet';

const organizationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Middle Way',
  image: 'https://middleway.in.ua/logo.jpg',
  url: 'https://middleway.in.ua',
  sameAs: [
    'https://www.instagram.com/middleway_vasylkiv/',
    'https://www.facebook.com/middleway.stolychnyy',
    'https://www.facebook.com/school.middleway',
  ],
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+38 067 447 42 95',
    contactType: 'Customer Support',
  },
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'UA',
    addressRegion: 'Київська область',
    addressLocality: 'с. Путрівка',
    streetAddress: 'вул. Газова, 8',
  },
};

const offerCatalogSchema = {
  '@context': 'https://schema.org',
  '@type': 'OfferCatalog',
  name: 'Приватний садок Middle Way',
  itemListElement: [
    {
      '@type': 'Offer',
      name: 'Ранній розвиток - Я з мамою',
      description:
        "1,5-2 роки. 09:30-11:00 понеділок/четвер. 10:30-12:00 вівторок/п'ятниця. Монтессорі метод. Творча робота. Напій зі смаколиками. Повноцінний обід за бажанням",
      priceCurrency: 'UAH',
      price: '1600',
      priceValidUntil: 'P1M',
      itemOffered: {
        '@type': 'Service',
        serviceType: 'Ранній розвиток',
      },
      availability: 'https://schema.org/InStock',
    },
    {
      '@type': 'Offer',
      name: 'Повний день Toddler',
      description:
        '1,5-3 роки. 08:00 - 19:00 Пн-Пт. 5-разове харчування. Монтессорі метод. Заняття з фахівцями. Спортивно-рухова активність',
      priceCurrency: 'UAH',
      price: '11800',
      priceValidUntil: 'P1M',
      itemOffered: {
        '@type': 'Service',
        serviceType: 'Дошкільне виховання',
      },
      availability: 'https://schema.org/InStock',
    },
    {
      '@type': 'Offer',
      name: 'Повний день Preschool',
      description:
        '3-6 років. 09:30-11:00 Пн-Пт. 5-разове харчування. Монтессорі метод. Заняття з фахівцями. Спортивно-рухова активність',
      priceCurrency: 'UAH',
      price: '11800',
      priceValidUntil: 'P1M',
      itemOffered: {
        '@type': 'Service',
        serviceType: 'Дошкільне виховання',
      },
      availability: 'https://schema.org/InStock',
    },
  ],
};

const childcareSchemas = [
  {
    '@context': 'https://schema.org',
    '@type': 'ChildCare',
    name: 'Приватний Садок Middle Way с. Путрівка Фастівський район',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'UA',
      addressRegion: 'Київська область',
      addressLocality: 'с. Путрівка',
      streetAddress: 'вул. Газова, 8',
    },
    telephone: '+38 067 447 42 95',
  },
  {
    '@context': 'https://schema.org',
    '@type': 'ChildCare',
    name: 'Приватний садок Middle Way м. Васильків',
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'UA',
      addressRegion: 'Київська область',
      addressLocality: 'м. Васильків',
      streetAddress: 'вул. Декабристів, 151, корпус 5',
    },
    telephone: '+38 067 447 42 95',
  },
];

const schoolSchema = {
  '@context': 'https://schema.org',
  '@type': 'School',
  name: 'Путрівська приватна гімназія Middle Way',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'UA',
    addressRegion: 'Київська область',
    addressLocality: 'с. Путрівка',
    streetAddress: 'вул. Газова, 8',
  },
  telephone: '+38 067 447 42 95',
  hasOfferCatalog: {
    '@type': 'OfferCatalog',
    name: 'Школа Middle Way',
    itemListElement: [
      {
        '@type': 'Offer',
        name: 'Стандартний день',
        description:
          'Уроки до 15.00. Можливість перебувати до 19.00 - 100 грн./день. Окремо оплачуються гуртки',
        priceCurrency: 'UAH',
        price: '8000',
        priceValidUntil: 'P1M',
        itemOffered: {
          '@type': 'Service',
          serviceType: 'Освіта',
        },
        availability: 'https://schema.org/InStock',
      },
      {
        '@type': 'Offer',
        name: 'Повний день',
        description:
          'Перебування до 19.00. Безлім гуртків з 15.00 до 19.00 (окрім Smartum)',
        priceCurrency: 'UAH',
        price: '10000',
        priceValidUntil: 'P1M',
        itemOffered: {
          '@type': 'Service',
          serviceType: 'Освіта',
        },
        availability: 'https://schema.org/InStock',
      },
    ],
  },
};

const SchemaOrg = () => {
  const schemas = [
    organizationSchema,
    offerCatalogSchema,
    ...childcareSchemas,
    schoolSchema,
  ];

  return (
    <Helmet>
      {schemas.map((schema, index) => (
        <script
          key={index}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      ))}
    </Helmet>
  );
};

export default SchemaOrg;
